import { Overlay } from './overlay';
import { bindAll } from 'underscore'

export class RestaurantFinderOverlay extends Overlay {
    constructor(options) {
        super(options);
    }

    initialize() {
        super.initialize();
    }

    open(e){
        super.open(e);

        if(e.detail.id != this.el.id){
            return;
        }

        if(e.detail.type){
            this.el.querySelector("input[name='type']").value = e.detail.type;
        }
    }
}

Me.views['RestaurantFinderOverlay'] = RestaurantFinderOverlay;
