import {ViewBasic} from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore'

export class Inputs extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['changeHandler', 'focusHandler', 'blurHandler']);
        this.addEvents();
    }

    addEvents() {
        this.el.addEventListener('change', this.changeHandler);
        this.el.addEventListener('focus', this.focusHandler);
        this.el.addEventListener('blur', this.blurHandler);
    }

    removeEvents() {
        this.el.removeEventListener('change', this.changeHandler);
        this.el.removeEventListener('focus', this.focusHandler);
        this.el.removeEventListener('blur', this.blurHandler);
    }

    changeHandler(e) {
        if(this.el.value === ''){
            this.el.classList.remove('has-value');
        }
        else{
            this.el.classList.add('has-value');
            this.el.classList.remove('error');
        }
    }

    focusHandler(e){
        this.el.classList.add('has-value');
    }

    blurHandler(e){
        if(this.el.value === ''){
            this.el.classList.remove('has-value');
        }
    }
}

Me.views['Inputs'] = Inputs;
