import {ViewBasic} from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore';
import gsap from 'gsap';

export class ContentTable extends ViewBasic {
    constructor(options) {
        super(options);

        this.startX;
        this.currentX;
        this.preventSideScrolling = false;
    }

    initialize() {
        bindAll(this, ['initObservers', 'touchStart', 'touchMove', 'touchEnd', 'resize', 'updateOnVelocity', 'categoryClickHandler', 'itemClickHandler']);
        this.navContainer = this.el.querySelector('.table-content');
        this.navUl = this.navContainer.querySelector('ul');
        this.translateX = 0;

        this.initObservers();

        this.addEvents();
    }

    addEvents() {
        this.navUl.querySelectorAll('a').forEach(element => {
            element.addEventListener('click', this.categoryClickHandler);
        });
        this.navUl.addEventListener('touchstart', this.touchStart);
        this.navUl.addEventListener('touchmove', this.touchMove);
        this.el.querySelectorAll('.menu-content a').forEach(element => {
            element.addEventListener('click', this.itemClickHandler);
        });
        window.addEventListener('resize', this.resize);
    }

    removeEvents() {
        this.navUl.querySelectorAll('a').forEach(element => {
            element.removeEventListener('click', this.categoryClickHandler);
        });
        this.navUl.removeEventListener('touchstart', this.touchStart);
        this.navUl.removeEventListener('touchmove', this.touchMove);
        this.el.querySelectorAll('.menu-content a').forEach(element => {
            element.removeEventListener('click', this.itemClickHandler);
        });
        window.removeEventListener('resize', this.resize);
    }

    /**
     * Use local storage to be used for the Go Back cta to return to the correct hash to scroll back to the correct category
     * (sometimes we have a product in multiple categories)
     */
    itemClickHandler(event) {
        let category = event.target.closest('.category');
        if (category && category.id) {
            localStorage.setItem('foodCategory', category.id);
        }
    }

    /**
     * Prevent scrolling on the nav UL when we click on a category.
     * If the nav UL is side scrolling while we are anchor scrolling, some browser seems to bug and stop the page scrolling all together
     */
    categoryClickHandler(){
        this.preventSideScrolling = true;
        let scope = this;

        setTimeout(function(){
            scope.preventSideScrolling = false;
        },500);
    }

    /**
     * Snap back the menu to position 0 when we go back to desktop display.
     */
    resize() {
        // remove transform if element is no longer in horizontal mobile layout
        if (window.innerWidth > window.SETTINGS.BREAKPOINTS.SM_MAX) {
            let scope = this;
            scope.navUl.style.transform = `translateX(${0}px)`;
        }
    }

    touchStart(e) {
        if(this.velocityTween){
            this.velocityTween.kill();
        }

        this.startX = e.touches[0].clientX;
    }

    /**
     * COmpare previous position to current position, and displace nav ul on horizontal axis.
     * @param e
     */
    touchMove(e) {
        document.addEventListener('touchend', this.touchEnd);

        if (window.innerWidth <= window.SETTINGS.BREAKPOINTS.SM_MAX) {
            if (this.startX === null) return;

            this.currentX = e.touches[0].clientX;

            const diffX = this.startX - this.currentX
            this.translateX -= diffX;

            this.translateX = Math.min(0, Math.max(this.translateX, -this.navUl.offsetWidth + window.innerWidth - this.navUl.offsetLeft));

            this.navUl.style.transform = `translateX(${this.translateX}px)`;
            this.startX = this.currentX;

            //use velocity to ease nav ul just like a telephone.
            if(this.previousPosition){
                if(e.touches){
                    this.velocity =  e.touches[0].clientX - this.previousPosition;
                } else{
                    this.velocity =  e.clientX - this.previousPosition;
                }
            }

            if(e.touches) {
                this.previousPosition = e.touches[0].clientX;
            }else{
                this.previousPosition =  e.clientX;
            }

            e.preventDefault();
        }
    }

    touchEnd(e){
        document.removeEventListener('touchend', this.touchEnd);
        this.velocityTween = gsap.to(this, 0.5, {velocity: 0, onUpdate: this.updateOnVelocity});
    }

    updateOnVelocity(){
        this.translateX += this.velocity;

        this.navUl.style.transform = `translateX(${Math.min(0, Math.max(this.translateX, -this.navUl.offsetWidth + window.outerWidth - this.navUl.offsetLeft))}px)`;
    }

    /**
     * Init Intersection observer once.
     * @param e
     */
    initObservers(e) {
        const scrollRoot = this.el;
        const sections = [...this.el.querySelectorAll('div.category[id]')]
        let prevYPosition = 0;
        let direction = 'up';

        /**
         * Detect if entry intersecting is the right entry to be focused.
         * note: On a trouver ça d'un codepen et ça règlait le problème que nous avions 2 entré actives quand plusieurs section intersect
         * https://codepen.io/smashingmag/pen/XWRXVXQ?editors=0010
         * @param entry
         * @returns {boolean}
         */
        const shouldUpdate = (entry) => {
            if (direction === 'down' && !entry.isIntersecting) {
                return true;
            }

            if (direction === 'up' && entry.isIntersecting) {
                return true;
            }

            return false;
        }

        /**
         * return either a specific entry element, or last entry's element in the list
         * @param entry
         * @returns {*}
         */
        const getTargetSection = (entry) => {
            const index = sections.findIndex((section) => section == entry.target);

            if (index >= sections.length - 1) {
                return entry.target;
            } else {
                return sections[index + 1];
            }
        }

        /**
         * Make sure no active section is left behind whenw e want to select a new one
         */
        const removeActive = () => {
            const navLinks = this.el.querySelectorAll('nav li');
            navLinks.forEach(navLink => {
                navLink.classList.remove('active');
            });
        }

        /**
         * Intersect with the middle of the window. Sort out which section is the most active one
         * @type {IntersectionObserver}
         */
        const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (scrollRoot.scrollTop > prevYPosition) {
                        direction = 'down';
                    } else {
                        direction = 'up';
                    }

                    prevYPosition = scrollRoot.scrollTop;

                    const target = direction === 'down' ? getTargetSection(entry) : entry.target;

                    if (shouldUpdate(entry)) {
                        removeActive();

                        this.el.querySelector(`nav li a[href="#${target.id}"]`).parentElement.classList.add('active');
                        if (window.innerWidth <= window.SETTINGS.BREAKPOINTS.SM_MAX) {
                            this.scrollToSection();
                        }
                    }
                });
            },
           {
                root: null,
                rootMargin: '-50% 0px -50% 0px',
                threshold: 0
            });

        sections.forEach((section) => {
            observer.observe(section);
        });
    }

    /**
     * Make sure nav UL is visible in viewport, otherwise calculate its next position
     * @returns {boolean}
     */
    isOutOfViewport() {
        const activeLink = this.el.querySelector('.active');

        if (activeLink) {
            const activeLinkRect = activeLink.getBoundingClientRect();
            return (
                activeLinkRect.left < 15 ||
                activeLinkRect.right > (this.navContainer.getBoundingClientRect().width - 50)
            );
        }
    };

    /**
     * scroll nav UL in view based on active section.
     */
    scrollToSection(){
        const activeLink = this.el.querySelector('.active');

        if (this.isOutOfViewport()) {
            const activeLinkRect = activeLink.getBoundingClientRect();

            // calculate distance between first child left to active section child left
            let firstChild = this.navUl.firstElementChild;
            let firstChildRect = firstChild.getBoundingClientRect().left;
            let activeOffset = activeLinkRect.left;

            if(!this.preventSideScrolling){
                this.navUl.style.transform = `translateX(-${(activeOffset - firstChildRect)}px)`;
                this.translateX = - activeOffset - firstChildRect;
            }
        }
    }
}

Me.views['ContentTable'] = ContentTable;
