import { ViewBasic } from '@quatrecentquatre/manage-me';
import {Overlay} from "../overlay/overlay";
import { bindAll } from 'underscore'

export class RestaurantChange extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        super.initialize();
    }

    addEvents() {
        bindAll(this, ['changeRestaurant']);
        super.addEvents();

        this.el.querySelector('h3 button').addEventListener('click', this.changeRestaurant);
    }

    removeEvents() {
        super.addEvents();

        this.el.querySelector('h3 button').removeEventListener('click', this.changeRestaurant);
    }

    changeRestaurant() {
        window.dispatchEvent(new CustomEvent('Overlay.open',
            {
                detail: {
                    id: 'overlay-restaurant',
                    type: "menu"
                }
            }
        ));
    }
}

Me.views['RestaurantChange'] = RestaurantChange;
