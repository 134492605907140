import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore'

export class Accordion extends ViewBasic {
    constructor(options) {
        super(options);

        this.trigger = this.el.querySelector('.accordion-trigger');
        this.title = this.el.querySelector('.accordion-title');
        this.content = this.el.querySelector('.accordion-content');
    }

    initialize() {
        bindAll(this, ['resize','toggle','handleEndTransition']);
        this.addEvents();
    }

    addEvents() {
        window.addEventListener('resize', this.resize);

        this.trigger.addEventListener("click", this.toggle);
        if(!this.el.getAttribute('data-trigger-only')){
            this.el.addEventListener("click", this.toggle);
        }

        this.content.addEventListener('transitionend', this.handleEndTransition);
        if(this.title){
            this.title.addEventListener("click", this.toggle);
        }
    }

    removeEvents() {
        window.removeEventListener('resize', this.resize);

        this.trigger.removeEventListener("click", this.toggle);
        this.el.addEventListener("click", this.toggle);

        this.content.removeEventListener('transitionend', this.handleEndTransition);
        if(this.title) {
            this.title.removeEventListener("click", this.toggle);
        }
    }

    toggle(e){
        if(e.target.closest('.accordion-content')){
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        if(!this.el.classList.contains('active')){
            this.el.classList.add('is-opening');
        } else{
            this.el.classList.add('is-closing');
            this.el.classList.toggle('active');
        }

        if (this.content.style.maxHeight) {
            this.content.style.maxHeight = null;
        } else {
            this.content.style.maxHeight = this.content.scrollHeight + "px";
        }
    }

    handleEndTransition(e){
        const WATCHED_PROPERTY = ['max-height'];

        if(e.target === this.content && WATCHED_PROPERTY.includes(e.propertyName)){
            if(this.el.classList.contains('is-opening')){
                this.el.classList.toggle('active');
            }

            this.el.classList.remove('is-opening', 'is-closing');
        }
    }

    resize(){
        if(this.el.classList.contains('active')){
            this.content.style.maxHeight = this.content.scrollHeight + "px";
        }
    }
}

Me.views['Accordion'] = Accordion;
