class URLManager {
    updateURLParameter(param, value){
        var urlPartsArray = window.location.href.split('?');

        //if we have GET params
        if(urlPartsArray[1]){
            //split params
            var getParamsArray = urlPartsArray[1].split('&');
            //for rebuilding the new url
            var newParamsURL = '';
            //wether or not we found a match.
            var foundParamInURL = false;

            //loop through all get parameters to find a match with the requested changing parameter.
            getParamsArray.some((getParamValue,index)=>{
                //split key=value into an array and compare the key component with the requested changing parameters
                if(getParamValue.split('=')[0] == param){
                    //override the parameter's value with the new value
                    getParamsArray[index] = param + '=' + encodeURIComponent(value);

                    if(value == ''){
                        getParamsArray.splice(index,1);
                    }

                    //stop the loop and return;
                    foundParamInURL = true;
                    return true;
                }
            });

            //if no match in the previous code, add a new params to the list
            if(!foundParamInURL && value != ''){
                getParamsArray.push(param + '=' + encodeURIComponent(value));
            }

            //build the new url based on the array of params above.
            getParamsArray.forEach((getParamValue,index)=>{
                newParamsURL += getParamsArray[index];

                if(index < getParamsArray.length -1){
                    newParamsURL += '&';
                }
            });

            window.history.replaceState('', '', location.protocol + '//' + location.host + location.pathname + "?" + newParamsURL);
        }else{
            if(value != ''){
                //no params, so add one to the url with the key and value received in method's parameters.
                window.history.replaceState('', '', location.protocol + '//' + location.host + location.pathname + "?" + param + '=' + encodeURIComponent(value));
            }
        }
    }

    getParameter(paramName){
        var urlPartsArray = window.location.href.split('?');

        var value = '';

        //if we have GET params
        if(urlPartsArray[1]) {
            //split params
            var getParamsArray = urlPartsArray[1].split('&');

            getParamsArray.some((getParamValue,index)=> {
                //split key=value into an array and compare the key component with the requested changing parameters
                if (getParamValue.split('=')[0] == paramName) {
                    //save the value found
                    value = getParamValue.split('=')[1];
                    //stop foreach loop
                    return true;
                }
            });

            //return the value if we found one.
            if(value){
                return decodeURIComponent(value);
            }
        }else{
            return null;
        }
    }

    //find a param with a part (ex: utm in utm_source, utm_media, etc...)
    getMatchingParameterByPart(paramPart){
        var urlPartsArray = window.location.href.split('?');

        var params = '';

        //if we have GET params
        if(urlPartsArray[1]) {
            //split params
            var getParamsArray = urlPartsArray[1].split('&');

            getParamsArray.some((getParamValue,index)=> {
                //split key=value into an array and compare the key component with the requested changing parameters
                if (getParamValue.split('=')[0].indexOf(paramPart) != -1) {
                    //save the param found
                    //append & if we already have params found.
                    if(params){
                        params += '&';
                    }
                    params += getParamValue;
                }
            });

            //return the params if we found one.
            if(params){
                return decodeURIComponent(params);
            }
        }else{
            return null;
        }
    }
}

window.UrlManager = new URLManager();
