import { ViewBasic } from '@quatrecentquatre/manage-me';

export class GoBack extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        this.addEvents();

        // Change href of the Go Back cta to return to the correct hash to scroll to the correct category
        // (sometimes we have a product in multiple categories)
        if (this.el.classList.contains('use-storage')) {
            const foodCategory = localStorage.getItem('foodCategory');
            if (foodCategory) {
                const linkElement = this.el.querySelector('.return-cta');
                if (linkElement) {
                    linkElement.href =
                        linkElement.href.split('#')[0] +
                        '#' +
                        foodCategory +
                        '-' +
                        linkElement.dataset.slug;
                }
                // To avoid issues with pairings
                localStorage.removeItem('foodCategory');
            }
        }
    }

    addEvents() {}

    removeEvents() {}
}

Me.views['GoBack'] = GoBack;
