import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';
import SplitText from 'gsap/SplitText';
import {Overlay} from "./overlay/overlay";
import { bindAll } from 'underscore'

export class Tile extends ViewBasic{
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['openOverlay','closeOverlay']);

        this.addEvents();
    }

    addEvents() {
        if(this.el.dataset.overlay){
            this.el.addEventListener('click', this.openOverlay);
            window.addEventListener(Overlay.OVERLAY_CLOSE, this.closeOverlay);
        }
    }

    removeEvents() {
        if(this.el.dataset.overlay){
            this.el.removeEventListener('click', this.openOverlay);
            window.removeEventListener(Overlay.OVERLAY_CLOSE, this.closeOverlay);
        }
    }

    openOverlay(){
        window.UrlManager.updateURLParameter('promo',this.el.id);
        window.dispatchEvent(new CustomEvent('Overlay.open',
            {
                detail: {
                    id: 'overlay_' + this.el.id
                }
            }
        ));
    }

    closeOverlay(){
        if(window.UrlManager.getParameter('promo')){
            window.UrlManager.updateURLParameter('promo','');
        }
    }
}

Me.views['Tile'] = Tile;
