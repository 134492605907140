import { Overlay } from './overlay';
import { bindAll } from 'underscore'

export class OrderOverlay extends Overlay {
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['changeRestaurant']);

        super.initialize();
    }

    addEvents() {
        super.addEvents();

        this.el.querySelector('.like-h5 button').addEventListener('click', this.changeRestaurant);
    }

    changeRestaurant() {
        window.dispatchEvent(new CustomEvent('Overlay.open',
            {
                detail: {
                    id: 'overlay-restaurant',
                    type: "order"
                }
            }
        ));
    }

    removeEvents() {
        super.removeEvents();

        this.el.querySelector('.like-h5 button').removeEventListener('click', this.changeRestaurant);
    }
}

Me.views['OrderOverlay'] = OrderOverlay;
