import { Overlay } from './overlay';
import { bindAll } from 'underscore'
import gsap from "gsap";

export class MobileOverlay extends Overlay {
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['openRestaurantOverlay','openRestaurantBookingOverlay', 'openOrderOverlay', 'calculateWindowHeight']);
        super.initialize();
    }

    addEvents() {
        super.addEvents();

        let scope = this;

        if(this.el.querySelector('.menu')){
            this.el.querySelector('.menu').addEventListener('click', this.openRestaurantOverlay);
        }

        if(this.el.querySelector('.booking')){
            this.el.querySelector('.booking').addEventListener('click', this.openRestaurantBookingOverlay);
        }

        this.el.querySelectorAll('.order').forEach(function(element){
            element.addEventListener('click', scope.openOrderOverlay);
        });
        window.addEventListener('resize', this.calculateWindowHeight);
    }

    removeEvents() {
        super.removeEvents();

        let scope = this;

        if(this.el.querySelector('.menu')){
            this.el.querySelector('.menu').removeEventListener('click', this.openRestaurantOverlay);
        }
        this.el.querySelectorAll('.order').forEach(function(element){
            element.removeEventListener('click', scope.openOrderOverlay);
        });
    }

    calculateWindowHeight(){
        // grabs the height of the window (minus the search bar)
        let vh = window.innerHeight * 0.01;
        let wrapper = this.el.querySelector('.wrapper');
        // applying that height to the wrapper
        wrapper.style.setProperty('--vh', `${vh}px`);
    }

    animateIn(){
        let scope = this;

        this.calculateWindowHeight();

        if(window.isMobile){
            gsap.to(this.el, {
                duration: 0.55,
                opacity: 1,
                top:'0px',
                right: '0px',
                ease: 'power2.easeOut',
                onComplete: function(){
                    scope.el.querySelector('.logo').style.position = 'absolute';
                }
            });
        }else{
            gsap.to(this.el, {
                duration: 0.55,
                opacity: 1,
                right:'0px',
                top:'0px',
                ease: 'power2.easeOut'
            });
        }


    }

    animateOut(){
        let scope = this;

        if(window.isMobile){
            gsap.to(this.el, {
                duration: 0.45,
                opacity: 0,
                top:'-70px',
                right: '0px',
                ease: 'power1.easeIn',
                onComplete: function(){
                    scope.el.querySelector('.logo').style.position = 'fixed';
                }
            });
        }else{
            gsap.to(this.el, {
                duration: 0.45,
                opacity: 1,
                right:'-70vw',
                top:'0px',
                ease: 'power1.easeIn'
            });
        }

    }



    /**
     * Open order online overlay or restaurant selector overlay if we have no restaurant selected
     * The href will be '#' if we have no restaurant selected
     * @param e
     */
    openOrderOverlay(e){
        e.preventDefault();

        if(e.target.attributes.href.value == '#'){
            window.dispatchEvent(new CustomEvent('Overlay.open',
                {
                    detail: {
                        id: 'overlay-restaurant',
                        type: "order"
                    }
                }
            ));
        }else{
            window.dispatchEvent(new CustomEvent('Overlay.open',
                {
                    detail: {
                        id: 'overlay-order',
                        type: "order"
                    }
                }
            ));
        }
    }

    /**
     * Open restaurant selector if no restaurant is selected for booking button
     * @param e
     */
    openRestaurantBookingOverlay(e){
        e.preventDefault();
        window.dispatchEvent(new CustomEvent('Overlay.open',
            {
                detail: {
                    id: 'overlay-restaurant',
                    type: "reservation"
                }
            }
        ));
    }


    /**
     * Open restaurant selector if no restaurant is selected for menu button
     * @param e
     */
    openRestaurantOverlay(e){
        e.preventDefault();
        window.dispatchEvent(new CustomEvent('Overlay.open',
            {
                detail: {
                    id: 'overlay-restaurant',
                    type: "menu"
                }
            }
        ));
    }

}

Me.views['MobileOverlay'] = MobileOverlay;
