import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';
import { bindAll } from 'underscore';

export class ScrollingContainer extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['next', 'previous', 'resize']);

        this.antiSpam = false;
        this.eventsAdded = false;

        if (window.SETTINGS.BREAKPOINT == '768-1023') {
            this.gap = 10;
        } else {
            this.gap = 45;
        }

        //save nodeList to array, needed so we can use reverse() methode in this.previous()
        this.scrollingElements = Array.from(this.el.querySelectorAll('.tile'));
        //container with the scrolling tiles
        this.$list = this.el.querySelector('.list');

        document.documentElement.style.setProperty(
            '--tileSize',
            (this.el.offsetWidth - this.gap * 2) / 3 + 'px'
        );
        this.hammer = new Hammer(this.el.querySelector('.list'));

        this.addEvents();
    }

    addEvents() {
        if (window.innerWidth > window.SETTINGS.BREAKPOINTS.SM_MAX) {
            this.eventsAdded = true;
            this.el.querySelector('.next').addEventListener('click', this.next);
            this.el.querySelector('.previous').addEventListener('click', this.previous);
            this.hammer.on('swiperight', this.previous);
            this.hammer.on('swipeleft', this.next);
        }
        window.addEventListener('window.resize', this.resize);
    }

    removeEvents() {
        this.eventsAdded = false;
        this.el.querySelector('.next').removeEventListener('click', this.next);
        this.el.querySelector('.previous').removeEventListener('click', this.previous);
        this.hammer.off('swiperight', this.previous);
        this.hammer.off('swipeleft', this.next);
        gsap.set(this.$list, { x: 0 });
        window.removeEventListener('window.resize', this.resize);
    }

    resize() {
        if (window.SETTINGS.BREAKPOINT == '768-1023' || window.SETTINGS.BREAKPOINT == '767-') {
            this.gap = 10;
        } else {
            this.gap = 45;
        }

        if (window.innerWidth > window.SETTINGS.BREAKPOINTS.SM_MAX) {
            this.addEvents();
        } else if (this.eventsAdded) {
            this.removeEvents();
        }

        document.documentElement.style.setProperty(
            '--tileSize',
            (this.el.offsetWidth - this.gap * 2) / 3 + 'px'
        );
        document.documentElement.style.setProperty(
            '--tileSizeMenu',
            (this.el.offsetWidth - this.gap * 1) / 2 + 'px'
        );

        if (this.$list.getBoundingClientRect().width < this.el.getBoundingClientRect().width) {
            this.el.querySelector('.controls').classList.add('hide');
        } else {
            this.el.querySelector('.controls').classList.remove('hide');
        }
    }

    next() {
        let scope = this;

        try {
            if (!scope.antiSpam) {
                scope.antiSpam = true;
                this.scrollingElements.forEach(function (tile) {
                    let tileBoundingRect = tile.getBoundingClientRect();

                    //if tile is not completely visible in the container.
                    if (
                        tileBoundingRect.x + tileBoundingRect.width >
                        scope.el.getBoundingClientRect().x + scope.el.getBoundingClientRect().width
                    ) {
                        scope.el.querySelector('.previous').disabled = false;
                        //highest value between end of the container width and a calculation for next position. Allow to lock the slideshow to the end of the container.
                        let targetPosition = Math.min(
                            //removes x from container from list.x so it starts from 0. Then add tile.width
                            -scope.$list.getBoundingClientRect().x +
                                scope.el.getBoundingClientRect().x +
                                tileBoundingRect.width +
                                scope.gap,
                            //calculate max transformX based on list.width minus container.width.
                            scope.$list.getBoundingClientRect().width -
                                scope.el.getBoundingClientRect().width
                        );

                        if (
                            targetPosition ==
                            scope.$list.getBoundingClientRect().width -
                                scope.el.getBoundingClientRect().width
                        ) {
                            scope.el.querySelector('.next').disabled = true;
                        }
                        gsap.to(scope.$list, {
                            x: -targetPosition,
                            onComplete() {
                                scope.antiSpam = false;
                            },
                        });
                        //break foreach
                        throw 'Break';
                    } else {
                        scope.antiSpam = false;
                    }
                });
            }
        } catch (e) {
            if (e !== 'Break') throw e;
        }
    }

    previous() {
        let scope = this;

        try {
            if (!scope.antiSpam) {
                scope.antiSpam = true;
                this.scrollingElements.reverse().forEach(function (tile) {
                    let tileBoundingRect = tile.getBoundingClientRect();

                    //if tile.x is not in the container.
                    if (tileBoundingRect.x < scope.el.getBoundingClientRect().x) {
                        scope.el.querySelector('.next').disabled = false;
                        //smallest value between 0 and a calculation for next position. Allow to lock to transformX 0.
                        let targetPosition = Math.max(
                            //removes x from container from list.x so it starts from 0. then remove the tile.width
                            -scope.$list.getBoundingClientRect().x +
                                scope.el.getBoundingClientRect().x -
                                tileBoundingRect.width -
                                scope.gap,
                            0
                        );

                        if (targetPosition == 0) {
                            scope.el.querySelector('.previous').disabled = true;
                        }
                        gsap.to(scope.$list, {
                            x: -targetPosition,
                            onComplete() {
                                scope.antiSpam = false;
                            },
                        });
                        //break foreach
                        throw 'Break';
                    } else {
                        scope.antiSpam = false;
                    }
                });
            }
        } catch (e) {
            if (e !== 'Break') throw e;
        }
    }
}

Me.views['ScrollingContainer'] = ScrollingContainer;
