import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';
import SplitText from 'gsap/SplitText';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { bindAll } from 'underscore'

export class HeroHomepage extends ViewBasic{
    constructor(options) {
        super(options);
    }

    initialize() {
        let scope = this;
        bindAll(this, ['breakpointChanged','resize']);

        this.addEvents();

        this.timelineProgress = 0;

        document.fonts.ready.then(function () {
            //split text by line with GSAP plugin
            new SplitText(scope.el.querySelector('h1'), { type: "lines" });
            new SplitText(scope.el.querySelector('h2'), { type: "lines" });

            scope.generateTimeline();
        });
    }

    addEvents() {
        window.addEventListener("window.resize", this.resize);
        window.addEventListener("breakpointChanged", this.breakpointChanged);
        if(this.el.querySelector('.menu')){
            this.el.querySelector('.menu').addEventListener('click', this.openRestaurantOverlay);
        }
    }

    removeEvents() {
        window.removeEventListener("window.resize", this.resize);
        window.removeEventListener("breakpointChanged", this.breakpointChanged);
        if(this.el.querySelector('.menu')){
            this.el.querySelector('.menu').removeEventListener('click', this.openRestaurantOverlay);
        }
        this.clearTimeline();
    }

    /**
     * Open restaurant selector if no restaurant is selected for menu button
     * @param e
     */
    openRestaurantOverlay(e){
        e.preventDefault();
        window.dispatchEvent(new CustomEvent('Overlay.open',
            {
                detail: {
                    id: 'overlay-restaurant',
                    type: "menu"
                }
            }
        ));
    }

    /**
     * Reset timeline to prevent visual glitches and stacking of events
     */
    clearTimeline(){
        if(this.timeline){
            //reset some styling
            this.el.querySelector('.main-image').style.width = null;
            //reset timeline
            this.timeline.pause(0);
            ScrollTrigger.getById("hero-homepage").kill(true);
            this.timeline.clear();
            this.timeline = null;
        }
    }

    /**
     * Generate a timeline with scrolltrigger.
     * The animation is different for mobile and other resolutions
     */
    generateTimeline(){
        let scope = this;
        let $element = this.el;
        let $staticSection = $element.querySelector('.static-ctn');

        this.clearTimeline();

        this.timeline = gsap.timeline({
            scrollTrigger: {
                id: 'hero-homepage',
                trigger: $element.querySelector('.scroll-height'),
                start: "top",
                end: "bottom bottom",
                scrub: false,
                //markers: true,
                onUpdate: self => {
                    //save progress for further use outside this update function
                    scope.timelineProgress = self.progress;

                    //seek to a percentage of the timeline based on progress to play the animation
                    scope.timeline.seek(scope.timeline.duration() * self.progress, false);
                    //prevent timeline to autoplay.
                    scope.timeline.pause();

                    //stick and unstick part of the hero at a certain point in progress
                    if(self.progress >= 1 && !this.isMobile){
                        $staticSection.classList.add('scroll');
                        //position element with a top based on progress and element height so it doesnt move when we use position absolute instead of fixed.
                        $staticSection.style.top = `${($element.querySelector('.scroll-height').offsetHeight - $staticSection.offsetHeight) * 1}px`;
                    }else{
                        $staticSection.classList.remove('scroll');
                        $staticSection.style.top = '';
                    }

                    //debug
                    //console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());
                }
            }
        });

        //timeline animation list
        if(this.isMobile){
            this.timeline.to($element.querySelector('.static-content a'), {top: '-40px', duration: 2, ease: 'none'},0);
            this.timeline.to($element.querySelector('.static-content a'), {opacity: '0', duration: 1, ease: 'none'},0);
            this.timeline.to($element.querySelector('.static-content a'), {pointerEvents: 'none', duration: 0.01, ease: 'none'},1);
            this.timeline.to($element.querySelectorAll('h1 div'), {clipPath: 'inset(100% -100px 0 -100px)', top: '-40px', duration: 1, ease: 'none'}, 0);
            this.timeline.to($element.querySelectorAll('.subtitle'), {clipPath: 'inset(0 -100px 0% -100px)', top: '0', duration: 1, ease: 'none'}, 1);
            this.timeline.to($element.querySelectorAll('h2 div'), {clipPath: 'inset(0 -100px 0% -100px)', top: '0', duration: 1.3, ease: 'none'}, 1);
        }else{
            this.timeline.to($element.querySelector('.static-content a'), {top: '-100px', duration: 2, ease: 'none'}, 0);
            this.timeline.to($element.querySelector('.static-content a'), {opacity: '0', duration: 1, ease: 'none'}, 0);
            this.timeline.to($element.querySelector('.static-content a'), {pointerEvents: 'none', duration: 0.01, ease: 'none'}, 1);
            this.timeline.to($element.querySelectorAll('h1 div'), {clipPath: 'inset(100% -100px 0 -100px)', top: '-100px', duration: 2, ease: 'none'}, 0);
            this.timeline.to($element.querySelectorAll('.subtitle'), {clipPath: 'inset(0 -100px 0% -100px)', top: '0', duration: 1, ease: 'none'}, 2.5);
            this.timeline.to($element.querySelectorAll('h2 div'), {clipPath: 'inset(0 -100px 0% -100px)', top: '0', duration: 1.3, ease: 'none'}, 2.5);
            //filler timing. Since we use the scroll progress and the timeline duration in synch,
            // if we want everything to end before the end of the scroll we need to add time to the total duration of the timeline
            this.timeline.to($element, {top: '0', duration: 2, ease: 'none'},3.7);
        }

        //pause the timeline since it will be seeked with the scrolltrigger
        this.timeline.pause();

    }

    /**
     * Breakpoint change handler. Triggered from main.js
     * @param e
     *  e.detail contains a variable breakpoint equal to a string stating the size of the screen (i.e: 767-, 768-1024, etc);
     */
    breakpointChanged(e){
        //store mobile state so the right animation is used in the timeline.
        if(e.detail.breakpoint == '767-'){
            this.isMobile = true;
        }else{
            this.isMobile = false;
        }

        this.generateTimeline();
    }

    /**
     * Resize handler. Triggered from main.js
     */
    resize(){
        if(this.timeline){
            this.generateTimeline();
            //recreate timeline and reset its position to current progress so everything is recalculated.
            this.timeline.seek(this.timeline.duration() * this.timelineProgress);
        }
    }
}

Me.views['HeroHomepage'] = HeroHomepage;
