import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore'

export class Footer extends ViewBasic{
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['resize']);
        this.addEvents();
    }

    addEvents() {
        window.addEventListener('resize', this.resize);
    }

    removeEvents() {
        window.removeEventListener('resize', this.resize);
    }

    resize(){
        //remove 200px so the round corners of the end of pages has the footer behind it and overlap by 200 px.
        //prevent Apple elastic scrolling to reveal a white background above the footer if we scroll fast.
        document.documentElement.style.setProperty('--footerHeight',(this.el.offsetHeight - 200) + 'px');
    }
}

Me.views['Footer'] = Footer;
