import { Overlay } from './overlay';
import { bindAll } from 'underscore'

export class ReservationOverlay extends Overlay {
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['resize']);
        super.initialize();
    }

    addEvents() {
        super.addEvents();

        window.addEventListener('window.resize', this.resize);
    }

    removeEvents() {
        super.removeEvents();
        window.removeEventListener("window.resize", this.resize);
    }

    resize(){
        this.el.querySelector('iframe').style.height = '';
        this.el.querySelector('iframe').style.height = this.el.querySelector('.iframe-wrapper').offsetHeight + 'px';
    }
}

Me.views['ReservationOverlay'] = ReservationOverlay;
