import { ViewBasic } from '@quatrecentquatre/manage-me';
import {Overlay} from "../overlay/overlay";
import { bindAll } from 'underscore'

export class MenuNavigation extends ViewBasic{
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['openOverlay']);
        this.addEvents();
    }

    addEvents(){
        this.el.querySelector('.return-cta').addEventListener('click', this.openOverlay);
    }

    removeEvents(){
        this.el.querySelector('.return-cta').removeEventListener('click', this.openOverlay);
    }
    openOverlay(){
        window.dispatchEvent(new CustomEvent('Overlay.open',
            {
                detail: {
                    id: 'overlay-menu-navigation'
                }
            }
        ));
    }
}

Me.views['MenuNavigation'] = MenuNavigation;
