import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore'

export class Reservation extends ViewBasic{
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['openPopup']);

        this.addEvents();
        setTimeout(function(){
            window.dispatchEvent(new CustomEvent('Overlay.open',
                {
                    detail: {
                        id: 'overlay-reservation'
                    }
                }
            ));
        },100);
    }
 
    addEvents() {
        this.el.querySelector('.primary-cta').addEventListener("click", this.openPopup);
    }

    removeEvents() {
        this.el.querySelector('.primary-cta').removeEventListener("click", this.openPopup);
    }

    openPopup(){
        window.dispatchEvent(new CustomEvent('Overlay.open',
            {
                detail: {
                    id: 'overlay-reservation'
                }
            }
        ));
    }
}

Me.views['Reservation'] = Reservation;
