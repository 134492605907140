import {ViewBasic} from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore'

export class navTab extends ViewBasic {
    constructor(options) {
        super(options);
        this.border = this.el.querySelector('.border-bottom');
        this.tabs = this.el.querySelectorAll('.nav-btn');
    }

    initialize() {
        bindAll(this, ['orderOnline','switchTab', 'loadDefaultBorder']);
        this.addEvents();
        this.loadDefaultBorder();
    }

    addEvents() {
        if(this.el.querySelector('.order')){
            this.el.querySelector('.order').addEventListener('click', this.orderOnline);
        }
        this.el.querySelectorAll('.nav-btn').forEach((navBtn) => navBtn.addEventListener('click', this.switchTab));
    }

    removeEvents() {
        if(this.el.querySelector('.order')){
            this.el.querySelector('.order').removeEventListener('click', this.orderOnline);
        }
        this.el.querySelectorAll('.nav-btn').forEach((navBtn) => navBtn.removeEventListener('click', this.switchTab));
    }

    /**
     * Open order online overlay when the button order is present in the hero (restaurant page)
     * @param e
     */
    orderOnline(e){
        e.preventDefault();
        window.dispatchEvent(new CustomEvent('Overlay.open',
            {
                detail: {
                    id: 'overlay-order',
                    type: "order"
                }
            }
        ));
    }

    /**
     * Load active tab border on page load
     */
    loadDefaultBorder(){
        let scope = this
        let tab = this.el.querySelector('.nav-btn.active');
        scope.border.style.width = tab.offsetWidth + "px";
        scope.border.style.left = tab.offsetLeft + "px";
    }

    switchTab(e) {
        let scope = this;
        const border = this.el.querySelector('.border-bottom');
        const tabPanes = this.el.querySelectorAll('.tab-pane');

        e.preventDefault();
        const tab = e.target;
        const href = tab.getAttribute('href');
        if (href[0] === '#') {
            scope.tabs.forEach((item) => item.classList.remove('active'));
            tab.classList.add('active');
            tabPanes.forEach((pane) => pane.classList.remove('active'));
            this.el.querySelector(href).classList.add('active');
            border.style.width = tab.offsetWidth + "px";
            border.style.left = tab.offsetLeft + "px";
        }
    }

}

Me.views['navTab'] = navTab;
