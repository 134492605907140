import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore'

export class LoadMore extends ViewBasic{
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['loadMore']);
        this.addEvents();
    }

    addEvents() {
        this.el.querySelector('.load-more').addEventListener('click', this.loadMore)
    }

    removeEvents() {
        this.el.querySelector('.load-more').removeEventListener('click', this.loadMore)
    }

    /**
     * Load more result in the restaurant list
     * @param e
     */
    loadMore(e){
        e.preventDefault();
        let index = 0;

        //show all results
        this.el.querySelectorAll('div.hide').forEach(function(element){
                index++;
                element.classList.remove('hide');
        });

        this.el.querySelector('.load-more button').classList.add('hide');
    }
}

Me.views['LoadMore'] = LoadMore;
