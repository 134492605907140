import {ViewBasic} from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore'

export class Selects extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['changeHandler', 'removeClassOnSelected']);
        this.choices = this.el.parentNode.querySelector('.choices');
        this.choiceElems = this.el.parentNode.querySelectorAll('.choice');

        this.addEvents();
    }

    addEvents() {
        this.el.addEventListener('change', this.changeHandler);
        this.choices.addEventListener('transitionend', this.removeClassOnSelected);
        this.choiceElems.forEach((elem, index) => {
            elem.addEventListener('transitionend', e => {
                e.stopPropagation()
            })
        });
    }

    removeEvents() {
        this.el.removeEventListener('change', this.changeHandler);
        this.choices.removeEventListener('transitionend', this.removeClassOnSelected);
    }

    changeHandler(e) {
        if (this.el.value === '') {
            this.el.classList.remove('has-value');
        } else {
            this.el.parentNode.classList.add('is-closing-after-selected');
            this.el.classList.add('has-value');
            this.el.classList.remove('error');
        }
    }

    /**
     * Makes sure that the border on the choices container is only removed after the select is closed
     * @NOTE -- This is due to a potential bug in skin-me where the is-open & is-closing classes
     *          do not get applied if the user selects an option
     * @param e - current event
     */
    removeClassOnSelected(e) {
        if (this.el.parentNode.classList.contains('is-closing-after-selected')) this.el.parentNode.classList.remove('is-closing-after-selected');
    }
}

Me.views['Selects'] = Selects;
