import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';
import SplitText from 'gsap/SplitText';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { bindAll } from 'underscore'

export class Hero extends ViewBasic{
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['orderOnline','breakpointChanged','resize']);

        this.addEvents();

        this.timelineProgress = 0;

        //split text by line with GSAP plugin
        this.titleLines = new SplitText(this.el.querySelector('h1'), { type: "lines" });

        this.generateTimeline();
    }

    addEvents() {
        if(this.el.querySelector('.order')){
            this.el.querySelector('.order').addEventListener('click', this.orderOnline);
        }
        window.addEventListener("window.resize", this.resize);
        window.addEventListener("breakpointChanged", this.breakpointChanged);
    }

    removeEvents() {
        if(this.el.querySelector('.order')){
            this.el.querySelector('.order').removeEventListener('click', this.orderOnline);
        }
        window.removeEventListener("window.resize", this.resize);
        window.removeEventListener("breakpointChanged", this.breakpointChanged);
        this.clearTimeline();
    }

    /**
     * Open order online overlay when the button order is present in the hero (restaurant page)
     * @param e
     */
    orderOnline(e){
        e.preventDefault();
        window.dispatchEvent(new CustomEvent('Overlay.open',
            {
                detail: {
                    id: 'overlay-order',
                    type: "order"
                }
            }
        ));
    }

    /**
     * Reset timeline to prevent visual glitches and stacking of events
     */
    clearTimeline(){
        if(this.timeline){
            //reset timeline
            this.timeline.pause(0);
            ScrollTrigger.getById("hero").kill(true);
            this.timeline.clear();
            this.timeline = null;
        }
    }

    /**
     * Generate a timeline with scrolltrigger.
     * The animation is different for mobile and other resolutions
     */
    generateTimeline(){
        let scope = this;
        let $element = this.el;

        this.clearTimeline();

        this.timeline = gsap.timeline({
            scrollTrigger: {
                id: 'hero',
                trigger: $element,
                start: "top",
                end: "+=600",
                scrub: false,
                //markers: true,
                onUpdate: self => {
                    //save progress for further use outside this update function
                    scope.timelineProgress = self.progress;

                    //seek to a percentage of the timeline based on progress to play the animation
                    scope.timeline.seek(scope.timeline.duration() * self.progress, false);
                    //prevent timeline to autoplay.
                    scope.timeline.pause();

                    //debug
                    //console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());
                }
            }
        });

        gsap.to($element.querySelector('.main-image'), {width: '100%', duration: 1, delay: 0.2, ease: 'power2.inOut'});
        gsap.to($element.querySelector('h1'), {opacity: '1', duration: 0.01, delay: 0.2});
        gsap.to($element.querySelector('h5'), {opacity: '1', duration: 0.01, delay: 0.2});
        gsap.to($element.querySelectorAll('h1 div'), {clipPath: 'inset(0 0 0% 0)', top: '0', duration: .75, delay: 1.0, ease: 'power1.Out'});
        gsap.to($element.querySelector('h5'), {clipPath: 'inset(0 0 0% 0)', top: '0', duration: .75, delay: 1.1,  ease: 'power1.Out'});


        this.timeline.to($element.querySelector('.main-image img'), {scale: '1.1', duration: 3, ease: 'none'},0);

        //pause the timeline since it will be seeked with the scrolltrigger
        this.timeline.pause();

    }

    /**
     * Breakpoint change handler. Triggered from main.js
     * @param e
     *  e.detail contains a variable breakpoint equal to a string stating the size of the screen (i.e: 767-, 768-1024, etc);
     */
    breakpointChanged(e){
        //store mobile state so the right animation is used in the timeline.
        if(e.detail.breakpoint == '767-'){
            this.isMobile = true;
        }else{
            this.isMobile = false;
        }

        this.generateTimeline();
    }

    /**
     * Resize handler. Triggered from main.js
     */
    resize(){
        if(this.titleLines){
            this.titleLines.revert();
            this.titleLines = null;
        }

        //split text by line with GSAP plugin
        this.titleLines = new SplitText(this.el.querySelector('h1'), { type: "lines" });

        if(this.timeline){
            this.generateTimeline();
            //recreate timeline and reset its position to current progress so everything is recalculated.
            this.timeline.seek(this.timeline.duration() * this.timelineProgress);
        }
    }
}

Me.views['Hero'] = Hero;
