import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore'

const OVERLAY_OPEN = 'Overlay.open';
const OVERLAY_CLOSE = 'Overlay.close';

export class Overlay extends ViewBasic {
    static get OVERLAY_OPEN() { return OVERLAY_OPEN; }
    static get OVERLAY_CLOSE() { return OVERLAY_CLOSE; }

    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['open','close','requestClose','requestClose']);

        this.addEvents();
    }

    addEvents() {
        super.addEvents();

        window.addEventListener(OVERLAY_OPEN, this.open);
        window.addEventListener(OVERLAY_CLOSE, this.close);
        this.el.querySelector('.close').addEventListener('click', this.requestClose);
        this.el.querySelector('.backdrop').addEventListener('click', this.requestClose);
    }

    removeEvents() {
        window.removeEventListener(OVERLAY_OPEN, this.open);
        window.removeEventListener(OVERLAY_CLOSE, this.close);
        this.el.querySelector('.close').removeEventListener('click', this.requestClose);
        this.el.querySelector('.backdrop').removeEventListener('click', this.requestClose);
    }

    open(e){
        let scope = this;

        if(e.detail.id != this.el.id){
            return;
        }

        // this.animateIn();
        this.el.querySelector('.wrapper').scrollTop = 0;

        let options = {
            root: null,
            rootMargin: `0px 2000px 2000px 0px`,
            threshold: 1.0
        }

        this.observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting){
                        this.el.classList.remove('title-is-sticky')
                    }else{
                        this.el.classList.add('title-is-sticky')
                    }
                })
            },
            options
        );

        let target = this.el.querySelector('h3');


        //display overlay
        this.el.classList.add('active');
        this.el.setAttribute('aria-hidden', false);

        //remove overflow on body
        document.querySelector('html').classList.add('no-scroll');
        this.animateIn();

        setTimeout(function(){
            if(target){
                scope.observer.observe(target);
            }
        }, 500);
    }

    animateIn(){
    }

    requestClose(e){

        window.dispatchEvent(new CustomEvent(OVERLAY_CLOSE,
            {
                detail: {
                    id: this.el.id
                }
            }
        ));
    }

    close(e){
        if(e.detail.id == this.el.id || e.detail.force && this.el.classList.contains('active')){
            this.observer.disconnect();

            this.el.classList.remove('title-is-sticky')

            //hide overlay
            this.el.classList.remove('active');
            this.el.setAttribute('aria-hidden', true);

            //add overflow on body
            document.querySelector('html').classList.remove('no-scroll');
            this.animateOut();
        }
    }

    animateOut(){
    }
}

Me.views['Overlay'] = Overlay;
