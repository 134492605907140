window.SETTINGS = {
    BASE_URL: window.location.protocol + "//" + window.location.host + "/",
    CURRENT_URL: window.location.protocol + "//" + window.location.host + window.location.pathname,
    VERSIONNING: new Date().getTime(),
    BREAKPOINT: null,
    BREAKPOINTS: {
        LG_MIN: 1440,
        MD_MAX: 1440 - 1,
        MD_MIN: 1024,
        SM_MAX: 1024 - 1,
        SM_MIN: 768,
        XS_MAX: 768 - 1
    }
};

if (process.env.RECAPTCHA_SITE_KEY) {
    window.SETTINGS.RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY;
}
