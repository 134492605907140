import { ViewBasic } from '@quatrecentquatre/manage-me';
import {GiftCardForm} from "./giftcard-form";
import {bindAll} from "underscore";
import gsap from "gsap";
import CustomEase from "gsap/CustomEase";

export class CardDisplay extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['updateVisual']);

        this.animateIn = true;

        this.addEvents();

    }

    addEvents() {
        window.addEventListener(GiftCardForm.CARD_UPDATE_VISUAL, this.updateVisual);
    }

    removeEvents() {}

    updateVisual(e){
        let scope = this;
        let image = document.createElement('img');
        image.src = e.detail.src;
        image.classList.add('hide');
        scope.el.querySelector('.card').append(image);

        if(this.animateIn == false){
            if(scope.el.querySelector('img').src == e.detail.src){
                return;
            }

            image.classList.remove('hide');

            //update card
            if(scope.el.classList.contains('mobile-only')){
                gsap.to(image,{left: '100vw', duration: 0.001, ease: 'power2.out'})
                gsap.to(image,{left: '0', duration: 0.4, delay: 0.2, ease: 'power2.out'})
                gsap.to(scope.el.querySelector('img'),{left: '-100vw', duration: 0.40, delay: 0.2, ease: 'power2.out', onComplete: function(){
                        this.targets()[0].parentNode.removeChild(this.targets()[0]);
                    }
                })
            }else{
                gsap.to(image,{scale: 0.8, duration: 0.01, ease: 'power2.out'})
                gsap.to(image,{scale: 1, left: '20px', top:'-60px', rotation:-4, duration: 0.37, delay:0.2, ease: 'power3.out'})
                gsap.to(image,{zIndex: '2', duration: 0.001, delay: 0.57})
                gsap.to(image,{left: '0', top:'0',  rotation: 0,  duration: 0.3, delay: 0.57, ease: 'power3.in'})
                gsap.to(scope.el.querySelector('img'),{scale: 0.8, left: '-30px', top:'20vw', rotation:5, duration: 0.20, delay: 0.2, ease: 'power2.out'})
                gsap.to(scope.el.querySelector('img'),{zIndex: '1', duration: 0.001, delay: 0.57})
                gsap.to(scope.el.querySelector('img'),{left: '0px', top:'0px', rotation: 0, duration: 0.50, delay: 0.40, ease: 'power3.in', onComplete: function(){
                        while(scope.el.querySelectorAll('.card img').length > 1){
                            scope.el.querySelector('.card').removeChild(scope.el.querySelector('.card').getElementsByTagName('img')[0])
                        }
                    }
                })
            }
        }else{
            this.animateIn = false;
            //add first card and animate in. Will be triggered by the selection of the default value
            gsap.to(image,{left: '50vw', skewX:'-15deg', duration: 0.001, ease: CustomEase.create("custom", "M0,0 C0.104,0.204 0.358,1.25 1,1 ")})
            scope.el.querySelector('img').classList.remove('hide');
            gsap.to(image,{left: '0', skewX:'0deg', duration: 0.7, delay: 0.001, ease: CustomEase.create("custom", "M0,0 C0.104,0.204 0.358,1.25 1,1 ")})
        }
    }
}

Me.views['CardDisplay'] = CardDisplay;
