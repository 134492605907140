import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore'

export class Header extends ViewBasic{
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['openMobileOverlay', 'openRestaurantBookingOverlay', 'openRestaurantOverlay','openOrderOverlay']);

        this.addEvents();

        if (this.el.classList.contains('onsite')) {
            // Change the logo href to the main menu for the on site menu
            const menuLink = this.el.querySelector('.main-nav > li:first-child > a');
            this.el.querySelector('.logo').href = menuLink.href + '/' + menuLink.dataset.onsiteslug;
        }

        if(window.location.href == this.el.querySelector('.order').attributes.href.value){
            setTimeout(function() {
                window.dispatchEvent(new CustomEvent('Overlay.open',
                    {
                        detail: {
                            id: 'overlay-order'
                        }
                    }
                ));
            },0);
        }
    }

    addEvents() {
        let scope = this;
        if(this.el.querySelector('.mobile-menu')) {
            this.el.querySelector('.mobile-menu').addEventListener('click', this.openMobileOverlay);
        }
        if(this.el.querySelector('.menu')){
            this.el.querySelector('.menu').addEventListener('click', this.openRestaurantOverlay);
        }
        this.el.querySelectorAll('.booking').forEach(function(element){
            element.addEventListener('click', scope.openRestaurantBookingOverlay);
        });

        this.el.querySelectorAll('.order').forEach(function(element){
            element.addEventListener('click', scope.openOrderOverlay);
        });

        //let sections = document.querySelectorAll('.section:not(.overlay):not(header):not(footer)');
        let sections = [];
        document.querySelectorAll('.section').forEach(function(element){
            //all sections can trigger a background change on the header (if they have a background class (transparent, dark, light)), but we want to exclude some sections such as static overlays and header footer
            if(element.classList.contains('overlay')) {
                return;
            }
            if(element.tagName == 'HEADER' || element.tagName == 'FOOTER'){
                return
            }

            sections.push(element);
        });

        //root margin
        const observerOptions = {
            root: null,
            rootMargin: `-50px 0px ${window.innerHeight * -1 + this.el.offsetHeight}px`,
            threshold: 0,
        };

        // Initialize light background
        if(document.querySelector('body').classList.contains('light')){
            this.el.classList.remove('transparent');
            this.el.classList.remove('dark');
            this.el.classList.add('light');
        }

        this.observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {

                    if (entry.isIntersecting) {
                        if(entry.target.classList.contains('no-bg')){
                            this.el.classList.remove('light');
                            this.el.classList.remove('dark');
                            this.el.classList.add('transparent');
                        }else if(entry.target.classList.contains('dark')){
                            this.el.classList.remove('light');
                            this.el.classList.remove('transparent');
                            this.el.classList.add('dark');
                        }else if(entry.target.classList.contains('light')){
                            this.el.classList.add('light');
                            this.el.classList.remove('dark');
                            this.el.classList.remove('transparent');
                        }else{
                            //use page background to determine the right color for header if a section has no background class
                            if(document.querySelector('body').classList.contains('dark')){
                                this.el.classList.remove('light');
                                this.el.classList.remove('transparent');
                                this.el.classList.add('dark');
                            }else{
                                this.el.classList.remove('transparent');
                                this.el.classList.remove('dark');
                                this.el.classList.add('light');
                            }
                        }
                    }
                });
            },
            observerOptions
        );

        sections.forEach((el) => {
            this.observer.observe(el);
        });
    }

    removeEvents() {
        let scope = this;
        this.el.querySelector('.mobile-menu').removeEventListener('click', this.openMobileOverlay);
        if(this.el.querySelector('.menu')){
            this.el.querySelector('.menu').removeEventListener('click', this.openRestaurantOverlay);
        }
        this.el.querySelectorAll('.order').forEach(function(element){
            element.removeEventListener('click', scope.openOrderOverlay.bind(scope));
        })
        this.el.querySelectorAll('.booking').forEach(function(element){
            element.removeEventListener('click', scope.openRestaurantBookingOverlay.bind(scope));
        });
        this.observer.disconnect();
    }

    /**
     * Open order online overlay or restaurant selector overlay if we have no restaurant selected
     * The href will be '#' if we have no restaurant selected
     * @param e
     */
    openOrderOverlay(e){
        e.preventDefault();

        if(e.target.attributes.href.value == '#'){
            window.dispatchEvent(new CustomEvent('Overlay.open',
                {
                    detail: {
                        id: 'overlay-restaurant',
                        type: "order"
                    }
                }
            ));
        }else{
            window.dispatchEvent(new CustomEvent('Overlay.open',
                {
                    detail: {
                        id: 'overlay-order',
                        type: "order"
                    }
                }
            ));
        }
    }
    /**
     * Open restaurant selector if no restaurant is selected for menu button
     * @param e
     */
    openRestaurantOverlay(e){
        e.preventDefault();
        window.dispatchEvent(new CustomEvent('Overlay.open',
            {
                detail: {
                    id: 'overlay-restaurant',
                    type: "menu"
                }
            }
        ));
    }

    /**
     * Open restaurant selector if no restaurant is selected for booking button
     * @param e
     */
    openRestaurantBookingOverlay(e){
        e.preventDefault();
        window.dispatchEvent(new CustomEvent('Overlay.open',
            {
                detail: {
                    id: 'overlay-restaurant',
                    type: "reservation"
                }
            }
        ));
    }

    /**
     * Open mobile overlay when pressing the burger icon
     */
    openMobileOverlay(){
        window.dispatchEvent(new CustomEvent('Overlay.open',
            {
                detail: {
                    id: 'overlay-mobile-menu'
                }
            }
        ));
    }
}

Me.views['Header'] = Header;
