import { ServiceBase } from './service.base';

export class RestaurantFinderService extends ServiceBase {
    constructor(options) {
        super(options);
    }

    search(url, formData){

        const headers = {
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
        };

        if (document.documentElement.lang == 'en') {
            url = '/en' + url;
        }

        return this.fetch('POST', url, formData, {headers:headers}).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            // Error management for unique behavior wherever this service is called
            return Promise.reject(error);
        });
    }
}
