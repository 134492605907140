import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore'

export class PromotionsList extends ViewBasic{
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['handleInfosDisplay']);

        this.btnReveal = this.el.querySelector('.btn-reveal');
        this.addEvents();
    }

    addEvents() {
        if(this.btnReveal){
            this.btnReveal.addEventListener('click', this.handleInfosDisplay);
        }
    }

    removeEvents() {
        if(this.btnReveal) {
            this.btnReveal.removeEventListener('click', this.handleInfosDisplay);
        }
    }

    handleInfosDisplay(){
        this.el.querySelectorAll('.tile.hide').forEach((node,index)=>{
            node.classList.remove('hide');
            setTimeout(function(){
                node.classList.remove('revealing');
            }, 100 * index);
        });

        this.btnReveal.classList.add('hide');
        window.dispatchEvent(new CustomEvent('Tile.recalculate'));
    }
}

Me.views['PromotionsList'] = PromotionsList;
