import { Overlay } from './overlay';
import { bindAll } from 'underscore'

export class MenuTypeOverlay extends Overlay {
    constructor(options) {
        super(options);
    }

    initialize() {
        super.initialize();

        if(window.UrlManager.getParameter('menutype')){
            if('overlay_' + window.UrlManager.getParameter('menutype') == this.el.id){
                window.dispatchEvent(new CustomEvent('Overlay.open',
                    {
                        detail: {
                            id: this.el.id
                        }
                    }
                ));
            }
        }
    }
}

Me.views['MenuTypeOverlay'] = MenuTypeOverlay;
