import { ViewBasic } from '@quatrecentquatre/manage-me';
import {Overlay} from "../overlay/overlay";
import { bindAll } from 'underscore'

export class MenuTypeEvent extends ViewBasic{
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['openOverlay', 'closeOverlay']);
        this.addEvents();
    }

    addEvents(){
        this.el.addEventListener('click', this.openOverlay);
        window.addEventListener(Overlay.OVERLAY_CLOSE, this.closeOverlay);
    }

    removeEvents(){
        this.el.removeEventListener('click', this.openOverlay);
        window.removeEventListener(Overlay.OVERLAY_CLOSE, this.closeOverlay);
    }

    openOverlay(){
        window.UrlManager.updateURLParameter('menutype',this.el.id);
        window.dispatchEvent(new CustomEvent('Overlay.open',
            {
                detail: {
                    id: 'overlay_' + this.el.id
                }
            }
        ));
    }

    closeOverlay(){
        if(window.UrlManager.getParameter('menutype')){
            window.UrlManager.updateURLParameter('menutype','');
        }
    }
}

Me.views['MenuTypeEvent'] = MenuTypeEvent;
