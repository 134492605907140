import { ViewBasic } from '@quatrecentquatre/manage-me';
import { RestaurantFinderService } from './../../services/restaurant-finder';
import {Overlay} from "../overlay/overlay";
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { bindAll } from 'underscore'

export class RestaurantFinder extends ViewBasic{
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['updateRestaurantList','documentClick','focusSearchInput','keyPress','findLocationByInputValue','inputChange','findUserPosition','loadMore', 'findUserGeolocationSuccess', 'findUserGeolocationError', 'initScrollAnimation']);
        this.service = new RestaurantFinderService();
        this.autocompleteService = new google.maps.places.AutocompleteService()

        this.addEvents();
        this.initScrollAnimation();
    }

    addEvents() {
        window.addEventListener(Overlay.OVERLAY_OPEN, this.updateRestaurantList);
        document.addEventListener('click', this.documentClick);
        this.el.querySelector('input[name="location"]').addEventListener('focus', this.focusSearchInput);
        this.el.querySelector('input[name="location"]').addEventListener('keypress', this.keyPress);
        this.el.querySelector('.icon-search').addEventListener('click', this.findLocationByInputValue);
        this.el.querySelector('input[name="location"]').addEventListener('input', this.inputChange);
        this.el.querySelector('.user-position').addEventListener('click', this.findUserPosition);
        if(this.el.querySelector('.load-more')){
            this.el.querySelector('.load-more').addEventListener('click', this.loadMore);
        }
    }

    removeEvents() {
        window.removeEventListener(Overlay.OVERLAY_OPEN, this.updateRestaurantList);
        document.removeEventListener('click', this.documentClick);
        this.el.querySelector('input[name="location"]').removeEventListener('focus', this.focusSearchInput);
        this.el.querySelector('input[name="location"]').removeEventListener('keypress', this.keyPress);
        this.el.querySelector('input[name="location"]').removeEventListener('input', this.inputChange);
        this.el.querySelector('.icon-search').removeEventListener('click', this.findLocationByInputValue);
        this.el.querySelector('.user-position').removeEventListener('click', this.findUserPosition);
        if(this.el.querySelector('.load-more')){
            this.el.querySelector('.load-more').removeEventListener('click', this.loadMore);
        }

    }

    updateRestaurantList(e){
        if(e.detail.id == 'overlay-restaurant'){
            window.removeEventListener(Overlay.OVERLAY_OPEN, this.updateRestaurantList);
            this.el.classList.add('loading');
            this.search(true);
        }
    }

    /**
     * Hide results when click occurs out of the results and inputs
     * @param e
     */
    documentClick(e){
        if(e.target.parentElement){
            if ((e.target.parentElement.className !== 'autocompleteResults') && (e.target.parentElement.className !== 'autocompleteResults') && (e.target.tagName !== 'INPUT')) {
                this.el.classList.remove('show-autocomplete');
            }
        }

        if((e.target.classList.contains('prediction'))){
            this.el.querySelector('input[name="location"]').value = e.target.innerHTML;
            this.el.classList.remove('show-autocomplete');

            this.el.classList.add('loading');
            this.el.classList.remove('error-php');

            this.findLocationByInputValue();
        }
    }

    focusSearchInput(e){
        if (this.el.querySelector('input[name="location"]').value !== '') {
            this.el.classList.add('show-autocomplete');
        }
    }

    /**
     * On keypress ENTER on input location
     * @param e
     */
    keyPress(e){
        if (e.keyCode === 13) {
            e.preventDefault();
            this.el.classList.remove('show-autocomplete');
            this.findLocationByInputValue();
        }
    }

    inputChange(e){
        this.el.classList.add('show-autocomplete');
        this.getPlacePredictions(this.el.querySelector('input[name="location"]').value);
    }

    findUserPosition(e){
        e.preventDefault();
        this.el.classList.remove('error');
        this.el.classList.remove('error-php');
        this.el.classList.add('loading');
        navigator.geolocation.getCurrentPosition(this.findUserGeolocationSuccess, this.findUserGeolocationError, {
            maximumAge: 1,
            timeout: 10000
        });
    }

    findLocationByInputValue(){
        let scope = this;
        let geocoder = new google.maps.Geocoder();

        if(this.setTimeout){
            clearTimeout(this.setTimeout);
        }

        scope.el.classList.add('loading');

        geocoder.geocode({'address': scope.el.querySelector('input[name="location"]').value}, function (results, status) {
            if (status == 'OK') {
                scope.el.classList.remove('loading');
                scope.el.querySelector("input[name='lat']").value = results[0].geometry.location.lat();
                scope.el.querySelector("input[name='lng']").value = results[0].geometry.location.lng();
                scope.search();
            } else {
                scope.el.classList.remove('loading');
                scope.el.classList.add('error-php');
            }
        });
    }

    findUserGeolocationSuccess(position) {
        this.getCityByLatLng(position.coords.latitude, position.coords.longitude);
        this.el.querySelector("input[name='lat']").value = position.coords.latitude;
        this.el.querySelector("input[name='lng']").value = position.coords.longitude;
        this.search();
    }

    findUserGeolocationError() {
        this.el.classList.remove('loading');
        this.el.classList.add('error');
    }

    getCityByLatLng(lat, lng){
        var latlng = new google.maps.LatLng(lat, lng);
        var geocoder = new google.maps.Geocoder();

        var view = this;

        geocoder.geocode({'latLng': latlng}, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                // console.log(results);
                if (results[0]) {
                    //formatted address
                    view.el.classList.remove('error-php');
                    view.el.querySelector('input[name="location"]').value = results[0].formatted_address;
                } else {
                    view.el.classList.add('error-php');
                }
            } else {
                view.el.classList.add('error-php');
            }
        });
    }

    /**
     * Search for restaurant by sending form data (user's position)
     */
    search(keepRecentRestaurant = false){
        let scope = this;

        this.service.search(this.el.getAttribute('action'),new FormData(scope.el))
            .then(response => {
                //make sure to hide default generic errors in case of success
                scope.el.classList.remove('loading');
                scope.el.classList.remove('error');
                scope.el.classList.remove('error-php');
                scope.el.classList.remove('show-autocomplete');

                //unbind click on load more button
                if(scope.el.querySelector('.load-more')){
                    scope.el.querySelector('.load-more').removeEventListener('click', scope.loadMore);
                }

                //hide previous result, then append new results
                gsap.to(scope.el.querySelector('.restaurant-list'),
                    {opacity:0, duration: 0.66,
                        onComplete: function(){
                            //add new DOM elements. The elements are the restaurants list
                            scope.el.querySelector('.restaurant-list').innerHTML = response;

                            //recent (active) restaurant will be kept only on first load.
                            if(!keepRecentRestaurant && scope.el.querySelector('.current')){
                                scope.el.querySelector('.current').classList.add('hide');
                            }
                            //unbind click on new load more button
                            scope.el.querySelector('.load-more').addEventListener('click', scope.loadMore.bind(scope))
                            //show results
                            gsap.to(scope.el.querySelector('.restaurant-list'), {opacity:1, duration: 0.66, delay: 0.25});
                        }
                    }
                );

            })
            .catch(error => {
                console.log(error);
                this.el.classList.remove('loading');
                this.el.classList.add('error-php');
            });
    }

    /**
     * Load more result in the restaurant list
     * @param e
     */
    loadMore(e){
        e.preventDefault();
        let index = 0;

        //hide button if end of list
        if(this.el.querySelectorAll('.restaurant.hide').length <= 6) {
            this.el.querySelector('.load-more').classList.add('hide');
        }

        //show next 6 results
        this.el.querySelectorAll('.restaurant.hide').forEach(function(element){
            if(index < 6){
                index++;
                element.classList.remove('hide');
            }
        });

        ScrollTrigger.refresh();
    }

    getPlacePredictions(search) {
        let view = this;

        //prevent short search to cut cost.
        if(search.length < 3) {
            view.autocompleteResults = [];
            this.el.classList.remove('show-autocomplete');
            return;
        }

        //prevent multiple quick search to reduce cost.
        //Make one search after last input with a delay of 500ms before calling the search.
        if(this.setTimeout){
            clearTimeout(this.setTimeout);
        }
        this.setTimeout = setTimeout( function(){
            view.autocompleteService.getPlacePredictions({
                input: search,
                types: [],
                componentRestrictions: { country: "ca" }
            }, function(predictions, status){

                var filteredPrediction = [];

                if(predictions){
                    predictions.forEach(function(prediction,index){
                        // console.log(prediction);
                        //show only cities, route (address) or postal code.
                        if(prediction.types.some(function(value){
                            return value.indexOf('postal') != -1 || value.indexOf('route') != -1|| value.indexOf('locality') != -1;
                        })){
                            filteredPrediction.push(prediction);
                        }
                    });
                }

                //empty results
                view.el.querySelector('.autocompleteResults').innerHTML = '';

                //push new results
                filteredPrediction.some(function(prediction){
                    view.el.querySelector('.autocompleteResults').innerHTML += `<li class="prediction">${prediction.description}</li>`;
                });
            });
        }, 500);

    }

    initScrollAnimation () {
        const restaurants = document.querySelectorAll('.restaurant-list > .restaurant');
        if (restaurants.length) {
            restaurants.forEach((restaurant) => {
                let scrollTriggerOptions = {
                    trigger: restaurant,
                    start: 'top bottom',
                    end: '+=250',
                    scrub: 0.5,
                };

                gsap.fromTo(
                    restaurant,
                    { y: 60 },
                    {
                        y: 0,
                        scrollTrigger: scrollTriggerOptions,
                    }
                );

                gsap.fromTo(
                    restaurant.querySelector('.restaurant-separator'),
                    { y: 80 },
                    {
                        y: 0,
                        scrollTrigger: scrollTriggerOptions,
                    }
                );
            })
        }
    }
}

Me.views['RestaurantFinder'] = RestaurantFinder;
