import { Overlay } from './overlay';
import { bindAll } from 'underscore'

export class MenuTypeCategoryOverlay extends Overlay {
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['requestClose']);
        super.initialize();
        this.addEvents();

        if(window.UrlManager.getParameter('menutype-category')){
            if('overlay_' + window.UrlManager.getParameter('menutype-category') == this.el.id){
                window.dispatchEvent(new CustomEvent('Overlay.open',
                    {
                        detail: {
                            id: this.el.id
                        }
                    }
                ));
            }
        }
    }
    addEvents() {
        super.addEvents();
        let links = this.el.querySelectorAll('ul a');

        links.forEach((link)=>{
            link.addEventListener('click', this.requestClose);
        });

    }

    removeEvents() {
        super.removeEvents();
        let links = this.el.querySelectorAll('ul a');

        links.forEach((link)=>{
            link.removeEventListener('click', this.requestClose);
        });

    }

    requestClose(){
        if(window.UrlManager.getParameter('menutype-category')){
            if('overlay_' + window.UrlManager.getParameter('menutype-category') == this.el.id){
                window.UrlManager.updateURLParameter('menutype-category','');
                window.dispatchEvent(new CustomEvent('Overlay.close',
                    {
                        detail: {
                            id: this.el.id
                        }
                    }
                ));
            }
        }
    }
}

Me.views['MenuTypeCategoryOverlay'] = MenuTypeCategoryOverlay;
