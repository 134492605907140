import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore'

export class PagebuilderInfosColumns extends ViewBasic{
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['handleInfosDisplay']);

        this.btnReveal = this.el.querySelector('.btn-reveal');
        this.addEvents();
    }

    addEvents() {
        if(this.btnReveal){
            this.btnReveal.addEventListener('click', this.handleInfosDisplay);
        }
    }

    removeEvents() {
        if(this.btnReveal) {
            this.btnReveal.removeEventListener('click', this.handleInfosDisplay);
        }
    }

    handleInfosDisplay(){
        this.el.querySelectorAll('.column-item.desktop-only').forEach((node,index)=>{
            node.classList.remove('desktop-only');
        });

        this.btnReveal.classList.add('hide');
    }
}

Me.views['PagebuilderInfosColumns'] = PagebuilderInfosColumns;
